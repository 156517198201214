import React from 'react';
import AppFooterLarge from 'web/components/AppFooterLarge';
import MainBusinessPageSection from './MainBusinessPageSection';
import MainCentralHubSection from './MainCentralHubSection';
import MainEasySchedulingSection from './MainEasySchedulingSection';
import MainFeaturesSection from './MainFeaturesSection';
import MainHeader from './MainHeader';
import MainHeroSection from './MainHeroSection';
import MainJoinNowSection from './MainJoinNowSection';
import MainOtherTestimonialsSection from './MainOtherTestimonialsSection';
import MainPrivateGroupSessionsSection from './MainPrivateGroupSessionsSection';
import MainSecurePaymentsSection from './MainSecurePaymentsSection';
import MainTechstarsSection from './MainTechstarsSection';
import MainTestimonailSection from './MainTestimonialSection';
import MainUsersSection from './MainUsersSection';
import MainVideoCallsSection from './MainVideoCallsSection';

const MainPage = () => {
  return (
    <>
      <MainHeader />
      <main>
        <MainHeroSection />
        <MainFeaturesSection />
        <MainTestimonailSection />
        <MainOtherTestimonialsSection />
        <MainBusinessPageSection />
        <MainEasySchedulingSection />
        <MainSecurePaymentsSection />
        <MainVideoCallsSection />
        <MainPrivateGroupSessionsSection />
        <MainCentralHubSection />
        <MainJoinNowSection />
        <MainUsersSection />
        <MainTechstarsSection />
      </main>
      <AppFooterLarge />
    </>
  );
};

export default MainPage;
