import React from 'react';
import Container from 'web/components/Container';

const MainTechstarsSection = () => (
  <Container
    as="section"
    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 80, marginBottom: 80 }}
  >
    <div>
      <a href="https://www.techstars.com/">
        {/* Negative space around the logo has to extend at least two underscores */}
        <img style={{ maxWidth: 320, padding: '12%' }} src="/ts_logo.svg" loading="lazy" title="Techstars" />
      </a>
    </div>
    <div>Introwise is a Techstars portfolio company</div>
  </Container>
);

export default MainTechstarsSection;
