import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

const useVideoAutoPlay = () => {
  const videoRef = useRef<HTMLVideoElement>();

  const [containerRef, inView] = useInView({
    threshold: 0.7,
  });

  useEffect(() => {
    const playPause = async (play: boolean) => {
      try {
        if (play) {
          await videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      } catch (err) {
        // play() or pause() may fail if the autoplay is blocked in the browser
        // do nothing and silently ignore the error
      }
    };

    void playPause(inView);
  }, [inView]);

  return [containerRef, videoRef] as const;
};

export default useVideoAutoPlay;
