import React from 'react';
import styled from 'styled-components';
import Container from 'web/components/Container';
import themeConstants from 'web/styles/themeConstants';

const SecurePaymentsHeading = styled.h2`
  margin-top: 0px;
  ${themeConstants.typography.h1}
`;

const PaymentProviderLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 32px 16px;

  > img {
    width: 100%;
    max-width: min(200px, 80%);
  }
`;

const SecurePaymentsLogoSection = styled.div``;

const SecurePaymentsContainer = styled(Container)`
  ${themeConstants.media.md} {
    display: grid;
    grid-template-columns: minmax(auto, 700px) 1fr;
    grid-template-rows: auto auto;

    ${SecurePaymentsLogoSection} {
      grid-area: 1 / 2 / 3 / 3;
    }
  }
`;

const MainSecurePaymentsSection = () => (
  <div style={{ backgroundColor: '#FCFAF5', padding: '60px 0', marginTop: 120 }}>
    <SecurePaymentsContainer>
      <div>
        <SecurePaymentsHeading>Secure and fast payments for your clients</SecurePaymentsHeading>
        <p>
          Your clients want to pay you as securely and effortlessly as possible. Give them their wish with Introwise. No
          matter where they are in the world, they’ll always be able to book a session and pay you in seconds. Your
          money goes into your account, hassle free. Business as it should be. Powered by our first-class integrations
          with Stripe and PayPal - the leading online payments processors trusted by thousands of businesses.
        </p>
      </div>
      <SecurePaymentsLogoSection>
        <PaymentProviderLogo>
          <img src="/main-page-stripe-logo.svg" loading="lazy" alt="Stripe" />
        </PaymentProviderLogo>
        <PaymentProviderLogo>
          <img src="/main-page-paypal-logo.svg" loading="lazy" alt="PayPal" />
        </PaymentProviderLogo>
      </SecurePaymentsLogoSection>
      <div>
        <p>
          Keeping track of your earnings is easier with Introwise. Simplify everything for yourself, your clients and
          your accountant.
        </p>
      </div>
    </SecurePaymentsContainer>
  </div>
);

export default MainSecurePaymentsSection;
