import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Container from 'web/components/Container';
import themeConstants from 'web/styles/themeConstants';

const EasySchedulingContainer = styled.div`
  ${themeConstants.media.sm} {
    display: grid;
    grid-template-columns: 1fr minmax(30%, 500px);
    column-gap: 40px;
  }
`;

const BloggingImageSquare = styled.div`
  padding-bottom: 100%;
  background: url('/signup-blogging.png') center/contain no-repeat;
`;

const EasySchedulingHeading = styled.h2`
  ${themeConstants.typography.hero};
`;

const EasySchedulingAnimatedList = styled.ul`
  display: grid;
  grid-template: auto / auto;
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  margin-top: 24px;

  > li {
    grid-area: 1 / 1 / 2 / 2;
    text-align: center;

    > div {
      display: inline-block;
      border-radius: ${themeConstants.borderRadius.sm};
      background-color: ${themeConstants.palette.mint[100]};
      padding: 8px 16px;
    }

    opacity: 0;

    &.visible {
      opacity: 1;
    }

    transition: opacity 400ms ease;
  }
`;

const schedulingFeaturesList = [
  '📆 Clients check your availability in real time',
  '💰 Clients book sessions on your Introwise page',
  '📧 Introwise sends email reminders',
  '⌚ Introwise takes care of rescheduling/cancellation',
  '✅ No date or timezone mix ups',
  '😄 Peace of mind for you and your clients',
];

const EasySchedulingFeatures = () => {
  const [currentIdx, setCurrentIdx] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => setCurrentIdx((prev) => (prev + 1) % schedulingFeaturesList.length), 3500);
    return () => clearInterval(interval);
  }, []);
  return (
    <EasySchedulingAnimatedList>
      {schedulingFeaturesList.map((feature, idx) => (
        <li
          key={idx}
          className={
            currentIdx === idx
              ? 'visible'
              : currentIdx === idx + 1 || (currentIdx === 0 && schedulingFeaturesList.length === idx + 1)
              ? 'just-hidden'
              : undefined
          }
        >
          <div>{feature}</div>
        </li>
      ))}
    </EasySchedulingAnimatedList>
  );
};

const MainEasySchedulingSection = () => (
  <Container as="section">
    <EasySchedulingContainer>
      <div>
        <EasySchedulingHeading>Easy scheduling for you and your clients</EasySchedulingHeading>
        <p>
          Your time is so valuable, use it to expand your business. We’ll make sure the day to day stuff runs smoothly.
          We free up the creative juices so you can think about the big picture: Getting paid to do what you love.
        </p>
        <p>
          Now you finally have time to dive into all the projects you’ve been putting off: post session questionnaires,
          video promos, more engaging presentations, or just getting enough sleep.
        </p>
        <EasySchedulingFeatures />
      </div>
      <div>
        <BloggingImageSquare />
      </div>
    </EasySchedulingContainer>
  </Container>
);

export default MainEasySchedulingSection;
