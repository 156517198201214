import React from 'react';
import styled from 'styled-components';
import Container from 'web/components/Container';
import themeConstants from 'web/styles/themeConstants';

const BusinessPageHeading = styled.div`
  > p {
    padding: 0px 0px;
  }
  ${themeConstants.media.md} {
    text-align: center;
    > p {
      padding: 0px 60px;
    }
  }
`;

const Title = styled.h2`
  margin-top: 0px;
  ${themeConstants.typography.h1}
`;

const BusinessPageFeatures = styled.div`
  ${themeConstants.media.md} {
    display: grid;
    grid-column-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const BusinessPageFeature = styled.div`
  position: relative;
  margin-top: 60px;
  padding: 32px;

  border-radius: ${themeConstants.borderRadius.lg};
  overflow: hidden;

  box-shadow: ${themeConstants.boxShadow.lg};
  background-color: ${themeConstants.palette.gray[50]};
`;

const BusinessPageFeatureTitle = styled.div`
  > h3 {
    margin-top: 6px;
  }
`;

const BusinessPageFeatureImage = styled.div`
  margin: 36px -32px -32px -32px;
  line-height: 0;
  > img {
    width: 100%;
  }
`;

const BusinessPageContainer = styled.div`
  padding: 20px;
  margin: -20px;
  ${themeConstants.media.md} {
    padding: 60px;
  }

  border-radius: ${themeConstants.borderRadius.lg};
  background-color: ${themeConstants.palette.blue[100]};
`;

const MainBusinessPageSection = () => (
  <div style={{ padding: '120px 0 160px' }}>
    <Container as="section">
      <BusinessPageContainer>
        <BusinessPageHeading>
          <Title>Your Personalized Business Page</Title>
          <p>
            The foundation of your online business is your webpage. Informative, stylish and interactive pages drive
            traffic. Thanks to Introwise, you don’t need to hire a web designer, take online programming courses or
            spend sleepless nights trying to make your page pop. We do it for you. It’s that simple. Just give us a
            couple of photos, your availability and price structure, and some interesting facts you want your clients to
            know about you.
          </p>
        </BusinessPageHeading>
        <BusinessPageFeatures>
          <BusinessPageFeature>
            <BusinessPageFeatureTitle>
              <h3>No coding required</h3>
            </BusinessPageFeatureTitle>
            <p>Create a beautiful page with all your services in minutes.</p>
            <BusinessPageFeatureImage>
              <img src="/main-page-business-page.jpg" loading="lazy" alt="Business page" />
            </BusinessPageFeatureImage>
          </BusinessPageFeature>
          <BusinessPageFeature>
            <BusinessPageFeatureTitle>
              <h3>Instant bookings</h3>
            </BusinessPageFeatureTitle>
            <p>Clients can easily view your availability and book instantly.</p>
            <BusinessPageFeatureImage>
              <img src="/main-page-booking-slot.jpg" loading="lazy" alt="Booking slots" />
            </BusinessPageFeatureImage>
          </BusinessPageFeature>
          <BusinessPageFeature>
            <BusinessPageFeatureTitle>
              <h3>Share anywhere</h3>
            </BusinessPageFeatureTitle>
            <p>Use your personalized link anywhere on the web.</p>
            <BusinessPageFeatureImage>
              <img src="/main-page-share-link-twitter.jpg" loading="lazy" alt="Automatic meta image" />
            </BusinessPageFeatureImage>
          </BusinessPageFeature>
        </BusinessPageFeatures>
      </BusinessPageContainer>
    </Container>
  </div>
);

export default MainBusinessPageSection;
