import React from 'react';
import styled from 'styled-components';
import Container from 'web/components/Container';
import { LinkStyled } from 'web/components/elements';
import useVideoAutoPlay from 'web/hooks/useVideoAutoplay';
import themeConstants from 'web/styles/themeConstants';

const VideoArea = styled.div`
  grid-area: video;

  padding: 32px;
  ${themeConstants.media.md} {
    padding: 100px;
  }
`;

const HeadingArea = styled.div`
  grid-area: heading;
  align-self: center;
`;

const Section = styled.section`
  display: grid;

  grid-template-rows: 0px [top] min-content 1fr [middle] 1fr min-content [bottom];
  grid-template-columns: 36px auto 36px;
  grid-template-areas: '. container .' '. heading .' '. video .' '. video .' '. quotes .';

  ${themeConstants.media.md} {
    grid-template-rows: 0px [top] 1fr [middle] 1fr [bottom];
    grid-template-columns: 1fr 400px 0px auto 1fr;
    grid-template-areas: '. container container container .' '. video . heading .' '. video . quotes .';
  }

  ${themeConstants.media.lg} {
    grid-template-columns: 1fr 500px 80px auto 1fr;
  }
`;

const Heading = styled.h1`
  ${themeConstants.typography.h1}
  ${themeConstants.media.sm} {
    ${themeConstants.typography.hero}
  }
`;

const Quote = styled.p`
  text-align: center;
  font-size: 20px;
  margin-top: 24px;

  ${themeConstants.media.md} {
    text-align: left;
    font-size: 24px;
    margin-top: 28px;
  }
`;

const VideoCard = styled.div`
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0px 0px 0px 24px #e1f3f3;
  background-color: #e1f3f3;

  > video {
    width: 100%;
    height: auto;
    aspect-ratio: 0.5638;

    &::cue {
      font-size: 120%;
    }
  }
`;

const VideoExpertInfo = styled.div`
  margin-top: 32px;
  text-align: center;
`;

const VideoContainer = styled.div`
  transform: rotate(-1.8deg);
  ${themeConstants.media.md} {
    transform: rotate(-2.8deg);
  }
`;

const Video = () => {
  const [cardRef, videoRef] = useVideoAutoPlay();

  return (
    <VideoContainer ref={cardRef}>
      <VideoCard>
        <video loop muted playsInline style={{ outline: 'none' }} controls crossOrigin="anonymous" ref={videoRef}>
          <source src="/main-page-ida-testimonial.webm" type="video/webm" />
          <source src="/main-page-ida-testimonial.mp4" type="video/mp4" />
          <track label="English" kind="subtitles" srcLang="en" src="/main-page-ida-testimonial.vtt" default />
        </video>
      </VideoCard>
      <VideoExpertInfo>
        <LinkStyled to="/idaa">Ida Sadikhova</LinkStyled>, psychologist, Italy
      </VideoExpertInfo>
    </VideoContainer>
  );
};

const QuotesArea = styled.div`
  grid-area: quotes;
  position: relative;
`;

const QuotesText = styled.div`
  position: relative;
  ${themeConstants.media.md} {
    margin-left: 80px;
    margin-top: 80px;
  }
`;

const QuotationMark = styled.div`
  position: absolute;
  top: -48px;
  left: -40px;
  ${themeConstants.media.md} {
    top: -10px;
    left: -100px;
  }

  color: ${themeConstants.palette.gray[200]};
  opacity: 0.8;

  font-size: 400px;
  font-weight: bold;
  line-height: 1;

  user-select: none;
`;

const MainTestimonailSection = () => (
  <Section>
    <Container style={{ gridArea: 'container' }} />
    <div style={{ backgroundColor: '#E1F3F3', gridArea: 'top / 1 / middle / -1' }} />
    <div style={{ gridArea: 'middle / 1 / bottom / -1' }} />
    <HeadingArea>
      <Heading>
        Loved&nbsp;and used&nbsp;by
        <br />
        hundreds&nbsp;of experts
      </Heading>
      <p>Don&apos;t just take our word for it and listen to what others are saying.</p>
    </HeadingArea>
    <QuotesArea>
      <QuotationMark>”</QuotationMark>
      <QuotesText>
        <Quote>“It includes everything you need”</Quote>
        <Quote>“Super easy in use”</Quote>
        <Quote>“For people passionate about teaching”</Quote>
      </QuotesText>
    </QuotesArea>
    <VideoArea>
      <Video />
    </VideoArea>
  </Section>
);

export default MainTestimonailSection;
