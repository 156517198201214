import React from 'react';
import styled from 'styled-components';
import Container from 'web/components/Container';
import themeConstants from 'web/styles/themeConstants';

const Heading = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  > div {
    max-width: 650px;
  }

  h2 {
    ${themeConstants.typography.h1}
  }
`;

const FeaturesContainer = styled.div`
  margin-top: 40px;

  ${themeConstants.media.sm} {
    padding: 0 20px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 20px;
  }

  ${themeConstants.media.md} {
    padding: 0 40px;
  }

  ${themeConstants.media.xl} {
    padding: 0 120px;
  }
`;

const Feature = styled.div`
  padding: 40px 0px;
  ${themeConstants.media.sm} {
    padding: 40px;
  }
`;

const FeatureInner = styled.div`
  position: relative;
`;

const FeatureFlyOut = styled.div`
  position: absolute;
  text-align: right;
  top: -1em;
  ${themeConstants.typography.hero}
  --distance: -0.7ch;
  ${themeConstants.media.sm} {
    --distance: -1.8ch;
  }
`;

const FeatureCircle = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  top: -70px;
  --distance: -34px;
  ${themeConstants.media.sm} {
    --distance: -80px;
  }
`;

const FeatureText = styled.div`
  position: relative;
`;

const MainPrivateGroupSessionsSection = () => (
  <section style={{ overflowX: 'hidden' }}>
    <Container>
      <Heading>
        <div>
          <h2>Private sessions and group bookings</h2>
          <p>
            We don’t believe in charging you more for being successful. Teach one on one, or up to forty-two people at a
            time. All for one price.
          </p>
        </div>
      </Heading>
      <FeaturesContainer>
        <Feature>
          <FeatureInner>
            <FeatureCircle style={{ backgroundColor: '#FFF4E9', left: 'var(--distance)' }} />
            <FeatureFlyOut style={{ left: 'var(--distance)' }}>1-1</FeatureFlyOut>
            <FeatureText>
              We believe in giving you the freedom to choose your own...everything. Offer as many different services as
              you want (each with a different price, length, whatever you want). We won’t get in your way. And your
              clients will love the flexibility.
            </FeatureText>
          </FeatureInner>
        </Feature>
        <Feature>
          <FeatureInner>
            <FeatureCircle style={{ backgroundColor: '#FFE9E4', right: 'var(--distance)' }} />
            <FeatureFlyOut style={{ right: 'var(--distance)' }}>42</FeatureFlyOut>
            <FeatureText>
              Whether it’s a private call or a group class, clients book their spots separately at a set price. No more
              splitting the cost of a class, they’ll pay the same price even if there are last minute cancellations. The
              class says $10, they pay $10.
            </FeatureText>
          </FeatureInner>
        </Feature>
      </FeaturesContainer>
      {/* <ul>
        <li>Unlimited types of classes</li>
        <li>Each call has its own personalized price</li>
        <li>Set group sessions where each client pays a set fee, no matter how many participants</li>
      </ul> */}
    </Container>
  </section>
);

export default MainPrivateGroupSessionsSection;
