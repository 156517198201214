import React from 'react';
import styled from 'styled-components';
import Container from 'web/components/Container';
import useVideoAutoPlay from 'web/hooks/useVideoAutoplay';
import themeConstants from 'web/styles/themeConstants';

const VideoCallsContainer = styled.section`
  display: grid;

  grid-template-rows: 0px [top] min-content 1fr [middle] 1fr min-content [bottom];
  grid-template-columns: 36px auto 36px;
  grid-template-areas: '. container .' '. heading .' '. video .' '. video .' '. quotes .';

  ${themeConstants.media.md} {
    grid-template-rows: 0px [top] 1fr [middle] 1fr [bottom];
    grid-template-columns: 1fr min-content 0px 400px 1fr;
    grid-template-areas: '. container container container .' '. heading . video .' '. quotes . video .';
  }

  ${themeConstants.media.lg} {
    grid-template-columns: 1fr min-content 80px 500px 1fr;
  }
`;

const VideoArea = styled.div`
  grid-area: video;

  padding: 32px;
  ${themeConstants.media.md} {
    padding: 100px;
  }
`;

const HeadingArea = styled.div`
  grid-area: heading;
  align-self: center;
`;

const Heading = styled.h2`
  ${themeConstants.typography.h1}
  ${themeConstants.media.sm} {
    ${themeConstants.typography.hero}
  }
`;

const VideoContainer = styled.div`
  transform: rotate(1.8deg);
  ${themeConstants.media.md} {
    transform: rotate(2.8deg);
  }
`;

const VideoCard = styled.div`
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 0px 0px 12px #e1f3f3;
  background-color: #e1f3f3;
  line-height: 0;

  > video {
    width: 100%;
    aspect-ratio: 0.5357;
  }
`;

const FeatureVideoCallsVideo = () => {
  const [cardRef, videoRef] = useVideoAutoPlay();

  return (
    <VideoContainer ref={cardRef}>
      <VideoCard>
        <video ref={videoRef} loop muted playsInline style={{ outline: 'none' }} controls>
          <source src="/main-page-gif-call-join.webm" type="video/webm" />
          <source src="/main-page-gif-call-join.mp4" type="video/mp4" />
        </video>
      </VideoCard>
    </VideoContainer>
  );
};

const QuotesArea = styled.div`
  grid-area: quotes;
`;

const MainVideoCallsSection = () => (
  <VideoCallsContainer>
    <Container style={{ gridArea: 'container' }} />
    <div style={{ backgroundColor: '#E1F3F3', gridArea: 'top / 1 / middle / -1' }} />
    <div style={{ gridArea: 'middle / 1 / bottom / -1' }} />
    <HeadingArea>
      <Heading>High quality video calls with no extra downloads</Heading>
    </HeadingArea>
    <QuotesArea>
      <p>
        Your clients shouldn’t have to be tech geniuses. Our video call platform is as easy as checking your email: One
        click and you’re in. The focus should be on you, not getting to you.
      </p>
      <ul>
        <li>Security without passwords, just click and go</li>
        <li>No downloads, always up-to-date</li>
        <li>Works on any device</li>
        <li>Share screen and chat with clients</li>
        <li>Two people can simultaneously share their screens</li>
        <li>Built-in timer</li>
      </ul>
    </QuotesArea>
    <VideoArea>
      <FeatureVideoCallsVideo />
    </VideoArea>
  </VideoCallsContainer>
);

export default MainVideoCallsSection;
