import React from 'react';
import AppHeaderMarketing, { AppHeaderStickyContainer } from 'web/components/AppHeaderMarketing';
import Container from 'web/components/Container';

const MainHeader = () => (
  <AppHeaderStickyContainer>
    <Container fullWidth>
      <AppHeaderMarketing logoLook="gradient" />
    </Container>
  </AppHeaderStickyContainer>
);

export default MainHeader;
