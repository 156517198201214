import React from 'react';
import styled from 'styled-components';
import themeConstants from 'web/styles/themeConstants';
import { LinkStyled } from 'web/components/elements';
import Container from 'web/components/Container';

const Testimonial = styled.div``;

const TestimonialsGrid = styled.div`
  display: grid;
  row-gap: 40px;
  // ${themeConstants.media.sm} {
  //   grid-template-columns: repeat(3, minmax(0, 1fr));
  //   > *:nth-child(2) {
  //     order: -1;
  //   }
  // }

  ${themeConstants.media.sm} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  ${themeConstants.media.xl} {
    grid-template-columns: repeat(2, minmax(0, 420px));
    justify-content: space-around;
  }
`;

const ellipseRed =
  'data:image/svg+xml,%3Csvg width="1030" height="662" viewBox="0 0 1030 662" fill="none" xmlns="http://www.w3.org/2000/svg"%3E' +
  '%3Cpath opacity="0.2" d="M125.243 207.448C122.195 122.456 175.544 43.6699 259.085 28.0535C360.577 9.08153 588.001 120.003 583.579 223.229C579.61 315.905 415.476 381.307 323.059 388.506C189.281 398.926 128.463 297.252 125.243 207.448Z" fill="%23FFA695"/%3E' +
  '%3C/svg%3E%0A';

// const ellipseBlue =
//   'data:image/svg+xml,%3Csvg width="1030" height="662" viewBox="0 0 1030 662" fill="none" xmlns="http://www.w3.org/2000/svg"%3E' +
//   '%3Cpath d="M949.705 93.525C995.505 257.165 813.266 505.575 595 553.5C316.143 614.73 24.1491 453.06 29.6515 321.636C35.4767 182.5 197.783 224.016 482.5 161.5C696.713 114.465 897.5 -92.9999 949.705 93.525Z" fill="%23E1F3F3"/%3E' +
//   '%3C/svg%3E%0A';

const ellipseOtherRed =
  'data:image/svg+xml,%3Csvg width="82" height="84" viewBox="0 0 82 84" fill="none" xmlns="http://www.w3.org/2000/svg"%3E' +
  '%3Cpath d="M16.8525 19.3192C30.9237 9.10764 37.458 28.0955 45.6178 31.7816C55.3641 36.1844 64.4219 28.8654 72.6276 40.1726C80.8333 51.4797 61.9558 76.2338 38.4437 71.3101C13.7742 66.144 3.67984 28.8788 16.8525 19.3192Z" fill="%23FFE9E4"/%3E' +
  '%3C/svg%3E%0A';

const TestimonialPhoto = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 40px;
  padding-bottom: 20px;
`;

const TestimonialText = styled.div`
  flex-grow: 1;
  padding: 16px;
  ${themeConstants.media.sm} {
    padding-left: 20px;
    padding-right: 20px;
  }
  ${themeConstants.media.lg} {
    padding-left: 48px;
    padding-right: 48px;
  }

  > h3 {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 12px;
  }

  > div {
    text-align: center;
  }

  > p {
    position: relative;
    margin-top: 32px;
  }
`;

const TestimonialPhotoImg = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
`;

const QuotationMark = styled.span`
  position: absolute;
  top: -56px;
  left: -40px;
  ${themeConstants.media.md} {
    top: -20px;
    left: -64px;
  }

  color: ${themeConstants.palette.gray[200]};
  opacity: 0.8;

  font-size: 140px;
  font-weight: bold;
  line-height: 1;

  user-select: none;
`;

const MainOtherTestimonialsSection = () => (
  <div style={{ padding: '120px 0 60px' }}>
    <Container as="section">
      <TestimonialsGrid>
        {/* <Testimonial>
          <TestimonialPhoto style={{ background: `url('${ellipseBlue}') center 20px / 285px no-repeat` }}>
            <TestimonialPhotoImg src="/main-testimonial-scott.jpg" loading="lazy" />
          </TestimonialPhoto>
          <TestimonialText>
            <h2>Scott Dodson</h2>
            <div>Chief Marketing Officer</div>
            <div>
              <small>
                <LinkStyled to="/scottdodson">introwise.com/scottdodson</LinkStyled>
              </small>
            </div>
            <p>
              <QuotationMark>”</QuotationMark>
              As a CMO at an aggressively growing scaleup, time is my most valuable resource. I get a lot of ad hoc
              requests for consulting. Before, I wouldn’t even answer most of the time. Now, I can just send them my
              Introwise link. It&apos;s totally automated. If they are serious, the calls show up in my calendar and
              afterwards the money shows up in my bank.
            </p>
          </TestimonialText>
        </Testimonial> */}
        <Testimonial>
          <TestimonialPhoto style={{ background: `url('${ellipseRed}') 20% 0px / 445px no-repeat` }}>
            <TestimonialPhotoImg src="/main-testimonial-luba.jpg" loading="lazy" alt="Luba Diasamidze" />
          </TestimonialPhoto>
          <TestimonialText>
            <h3>Luba Diasamidze</h3>
            <div>Team&nbsp;&amp;&nbsp;Leadership Coach</div>
            <div>
              <small>
                <LinkStyled to="/upthink-coaching">introwise.com/upthink-coaching</LinkStyled>
              </small>
            </div>
            <p>
              <QuotationMark>”</QuotationMark>I run a global practice. Introwise makes international payments seamless
              and enables me to serve more clients worldwide. Their secure and convenient checkout makes it easy for my
              clients to pay.
            </p>
          </TestimonialText>
        </Testimonial>
        <Testimonial>
          <TestimonialPhoto style={{ background: `url('${ellipseOtherRed}') center -50px / 260px no-repeat` }}>
            <TestimonialPhotoImg src="/main-testimonial-sanjay.jpg" loading="lazy" alt="SanJay Shukla" />
          </TestimonialPhoto>
          <TestimonialText>
            <h3>SanJay Shukla</h3>
            <div>Certified Life Coach</div>
            <div>
              <small>
                <LinkStyled to="/sanjayshukla-lifearchitect">introwise.com/sanjayshukla-lifearchitect</LinkStyled>
              </small>
            </div>
            <p>
              <QuotationMark>”</QuotationMark>I love Introwise! It is simple, professional, effective. And it just has
              everything in one place which I need as a coach. It gave me wonderful results in terms of conversion. And
              I wish I wouldn&apos;t have spent too much time building my website and adding all the integrations,
              because Introwise has everything and you can start in minutes!
            </p>
          </TestimonialText>
        </Testimonial>
      </TestimonialsGrid>
    </Container>
  </div>
);

export default MainOtherTestimonialsSection;
