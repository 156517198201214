import React from 'react';
import styled from 'styled-components';
import Container from 'web/components/Container';
import themeConstants from 'web/styles/themeConstants';

const Heading = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  > div {
    max-width: 650px;
  }

  h2 {
    ${themeConstants.typography.h1}
  }
`;

const Images = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0px;
`;

const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: auto / auto;
  > * {
    grid-area: 1 / 1 / 2 / 2;
  }

  width: 80%;
  max-width: 600px;
`;

const Image = styled.div`
  border-radius: 12px;
  overflow: hidden;
  box-shadow: ${themeConstants.boxShadow.lg};
  border: 1px solid ${themeConstants.borders.regular};
  background-color: ${themeConstants.palette.mint[100]};

  line-height: 0;
  > img {
    width: 100%;
  }

  --distance: 20%;
  ${themeConstants.media.md} {
    --distance: 40%;
  }
  ${themeConstants.media.lg} {
    --distance: 60%;
  }
`;

const MainCentralHubSection = () => (
  <section style={{ padding: '80px 0 120px', overflow: 'hidden' }}>
    <Container>
      <Heading>
        <div>
          <h2>Central hub for your business</h2>
          <div>
            Sessions history, itemized earnings, clients&apos; contacts - all of your customer relationships in one
            place. Introwise keeps your records neatly organized, and right at your fingertips.
          </div>
        </div>
      </Heading>
    </Container>
    <Images>
      <ImagesContainer>
        <Image style={{ transform: 'translateX(calc(-1 * var(--distance))) translateY(-2%) scale(0.8)' }}>
          <img src="/main-page-platform-availability.png" loading="lazy" alt="Availability settings" />
        </Image>
        <Image style={{ transform: 'translateX(var(--distance)) translateY(-2%) scale(0.8)' }}>
          <img src="/main-page-platform-call.jpg" loading="lazy" alt="Video call" />
        </Image>
        <Image style={{ transform: 'translateX(0)' }}>
          <img src="/main-page-platform-dashboard.png" loading="lazy" alt="Business dashboard" />
        </Image>
      </ImagesContainer>
    </Images>
  </section>
);

export default MainCentralHubSection;
