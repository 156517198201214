import React from 'react';
import Container from 'web/components/Container';
import {
  featureIconCircle,
  featureIconImage,
  featureItemTitle,
  featureItemTitleHeading,
  featuresBlock,
  featuresHeading,
  featuresIcon,
  featuresItem,
} from './frontPage.css';

const MainFeaturesSection = () => (
  <div style={{ backgroundColor: '#FCFAF5', padding: '60px 0' }}>
    <Container as="section">
      <h2 className={featuresHeading}>
        Do what you love — share your expertise with other people. We handle the rest:
      </h2>
      <div className={featuresBlock}>
        <div className={featuresItem}>
          <div className={featureItemTitle}>
            <div className={featuresIcon}>
              <div className={featureIconCircle} style={{ backgroundColor: '#A4DADA' }} />
              <div className={featureIconImage} style={{ backgroundImage: `url('/main-page-calendar.svg')` }} />
            </div>
            <h3 className={featureItemTitleHeading}>Easy scheduling</h3>
          </div>
          <p>
            Your clients book a time in just a few clicks, and your availability is always up-to-date thanks to two-way
            calendar integrations.
          </p>
        </div>
        <div className={featuresItem}>
          <div className={featureItemTitle}>
            <div className={featuresIcon}>
              <div className={featureIconCircle} style={{ backgroundColor: '#FFD4A6' }} />
              <div className={featureIconImage} style={{ backgroundImage: `url('/main-page-bank.svg')` }} />
            </div>
            <h3 className={featureItemTitleHeading}>Payment processing</h3>
          </div>
          <p>
            Your clients can be from anywhere in the world. Accept credit card payments and receive payouts directly to
            your bank account.
          </p>
        </div>
        <div className={featuresItem}>
          <div className={featureItemTitle}>
            <div className={featuresIcon}>
              <div className={featureIconCircle} style={{ backgroundColor: '#66BDD3' }} />
              <div
                className={featureIconImage}
                style={{ backgroundImage: `url('/main-page-tablet.svg')`, top: 4, right: 4 }}
              />
            </div>
            <h3 className={featureItemTitleHeading}>Video calls</h3>
          </div>
          <p>
            Video calls start right on Introwise.com and work on any device — no downloads needed for you or your
            clients .
          </p>
        </div>
      </div>
    </Container>
  </div>
);

export default MainFeaturesSection;
