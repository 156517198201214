import styled from 'styled-components';
import { LinkButton } from 'web/components/elements';
import themeConstants from 'web/styles/themeConstants';

const MainPageButton = styled(LinkButton)`
  width: 100%;

  height: 64px;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;

  ${themeConstants.media.sm} {
    width: 330px;
  }
`;

export default MainPageButton;
