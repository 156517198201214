import {
  faAppleAlt,
  faBaby,
  faBook,
  faCocktail,
  faDatabase,
  faFlag,
  faGuitar,
  faHeart,
  faLeaf,
  faMagic,
  faMusic,
  faOm,
  faPaintBrush,
  faPalette,
  faRunning,
  faShapes,
  faTools,
  faTshirt,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import Container from 'web/components/Container';
import themeConstants from 'web/styles/themeConstants';

const UsersTitle = styled.h2`
  text-align: center;
`;

const UsersList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  max-width: 800px;
  padding: unset;
  margin-left: auto;
  margin-right: auto;

  > li {
    display: block;
    white-space: nowrap;
    flex: none;
    margin: 4px 5px;
    padding: 1px 8px;
    border-radius: 20px;
    border: 1px solid ${themeConstants.palette.peach[550]};

    > svg {
      margin-right: 6px;
    }
  }
`;

const MainUsersSection = () => (
  <Container as="section" style={{ marginTop: 120, marginBottom: 80 }}>
    <UsersTitle>For all sorts of real-world experts</UsersTitle>
    <UsersList>
      <li>
        <FontAwesomeIcon icon={faFlag} />
        business coaches
      </li>
      <li>
        <FontAwesomeIcon icon={faShapes} />
        psychologists
      </li>
      <li>
        <FontAwesomeIcon icon={faHeart} />
        life coaches
      </li>
      <li>
        <FontAwesomeIcon icon={faAppleAlt} />
        nutritionists
      </li>
      <li>
        <FontAwesomeIcon icon={faRunning} />
        personal trainers
      </li>
      <li>
        <FontAwesomeIcon icon={faLeaf} />
        gardeners
      </li>
      <li>
        <FontAwesomeIcon icon={faOm} />
        yoga teachers
      </li>
      <li>
        <FontAwesomeIcon icon={faBaby} />
        parenting experts
      </li>
      <li>
        <FontAwesomeIcon icon={faBook} />
        private teachers
      </li>
      <li>
        <FontAwesomeIcon icon={faPaintBrush} />
        makeup artists
      </li>
      <li>
        <FontAwesomeIcon icon={faTshirt} />
        stylists
      </li>
      <li>
        <FontAwesomeIcon icon={faGuitar} />
        music teachers
      </li>
      <li>
        <FontAwesomeIcon icon={faMusic} />
        DJs and music producers
      </li>
      <li>
        <FontAwesomeIcon icon={faVideo} />
        content creators
      </li>
      <li>
        <FontAwesomeIcon icon={faPalette} />
        artists and crafters
      </li>
      <li>
        <FontAwesomeIcon icon={faTools} />
        DIY experts
      </li>
      <li>
        <FontAwesomeIcon icon={faDatabase} />
        poker masters
      </li>
      <li>
        <FontAwesomeIcon icon={faMagic} />
        magicians
      </li>
      <li>
        <FontAwesomeIcon icon={faCocktail} />
        bartenders and chefs
      </li>
    </UsersList>
    <p style={{ textAlign: 'center' }}>You name it and we’ll tell you how Introwise can help</p>
  </Container>
);

export default MainUsersSection;
