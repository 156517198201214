import React from 'react';
import Container from 'web/components/Container';
import styled from 'styled-components';
import MainPageButton from './MainPageButton';
import themeConstants from 'web/styles/themeConstants';

const JoinNowTitle = styled.h2`
  text-align: center;
  margin-top: 0px;
  ${themeConstants.typography.h1}
`;

const MainJoinNowSection = () => (
  <div style={{ backgroundColor: 'rgba(255, 160, 122, 0.2)' }}>
    <Container as="section" style={{ maxWidth: 800, paddingTop: 100, paddingBottom: 100 }}>
      <JoinNowTitle>Join now and grow your business with Introwise</JoinNowTitle>
      <div style={{ marginTop: 27, display: 'flex', justifyContent: 'center' }}>
        <p style={{ maxWidth: 380, textAlign: 'center' }}>
          Switching from another platform or marketplace? Let us know and we{"'"}ll help you get started.
        </p>
      </div>
      <div style={{ textAlign: 'center', marginTop: 28 }}>
        <MainPageButton primary to="/get-started">
          Start for free now
        </MainPageButton>
      </div>
    </Container>
  </div>
);

export default MainJoinNowSection;
