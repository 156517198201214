import React from 'react';
import Container from 'web/components/Container';
import { heroBackground, heroHeading, heroSection, heroSubheading } from './frontPage.css';
import MainPageButton from './MainPageButton';

const MainHeroSection = () => (
  <>
    <div className={heroBackground}>
      <Container>
        <section className={heroSection}>
          <div>
            <h1 className={heroHeading}>
              Effortless scheduling
              <br />
              with&nbsp;payments
            </h1>
            <p className={heroSubheading}>
              Set your availability and price. Introwise&nbsp;will&nbsp;handle scheduling,
              payments&nbsp;and&nbsp;video&nbsp;calls. All in one place.
            </p>
            <div style={{ marginTop: 40 }}>
              <MainPageButton primary to="/get-started">
                Start for free
              </MainPageButton>
            </div>
          </div>
          <div />
        </section>
      </Container>
    </div>
  </>
);

export default MainHeroSection;
